import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Checkbox } from '@mui/material';
import { uuid } from 'uuidv4';
import Swal from 'sweetalert2';
import ButtonAppBar from './Appbar';
var jwt = require('jsonwebtoken');
const axios = require('axios').default;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function App() {
  const transactionID = uuid();
  const MERCHANT_KEY = process.env.REACT_APP_MERCHANT_KEY;
  const REST_END_POINT = process.env.REACT_APP_END_POINT;
  const [amount, setAmount] = React.useState(0.0004);
  const [type, setType] = React.useState("BNB");
  const [address, setAddress] = React.useState("0x2A1B53f3988c9574a5c93e5f0AF1bDfB1888030F");
  const [tokenAddress, setTokenAddress] = React.useState("0x5c8a1c1aff1868e437bea6ff4537c5ceccee9955");
  const [customerId, setCustomerId] = React.useState("CUST003241");
  const [customerName, setCustomerName] = React.useState("Developer");
  const [customerEmail, setCustomerEmail] = React.useState("developer@walletpayment.net");
  const [customerPhone, setCustomerPhone] = React.useState("9876543210");
  const [value, setValue] = React.useState(0);
  const [coolingPeriod, setCoolingPeriod] = React.useState(30);
  const [minCoins, setMinCoins] = React.useState(0.002);
  const [maxCoins, setMaxCoins] = React.useState(1);
  const [isToken, setToken] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleWhitelist = () => {
    var token = jwt.sign({
      "address": address,
      "type": type,
      "customerName": customerName,
      "customerEmail": customerEmail,
      "customerPhone": customerPhone,
      "customerId": customerId,
      "minCoins": minCoins,
      "maxCoins": maxCoins,
      "coolingPeriod": coolingPeriod
    }, MERCHANT_KEY, {
      issuer: "walletpayment.net",
      expiresIn: "200m"
    });

    axios.post(REST_END_POINT + "/whitelist", {}, {
      headers: {
        "Authorization": token
      },
    }).then(response => {
      console.log(response.request.res.responseUrl);
      debugger;

    }).catch(function (err) {
      debugger;
      Swal.fire({
        icon: 'error',
        title: 'Error in Processing your request',
        html: err.response.data,
        footer: '<a href="">Why do I have this issue?</a>'
      })
    });
  }

  const hanldeDeposit = () => {
    var token = jwt.sign({
      'txnid': transactionID,
      'amount': amount,
      'type': type,
      'address': address,
      'cid': customerId,
      'name': customerName,
      'email': customerEmail,
      'mobile': customerPhone,
      'tokenAddress': isToken ? tokenAddress : "",
      'successUrl': process.env.REACT_APP_SUCCESS_URL,
      'failureUrl': process.env.REACT_APP_FAILURE_URL,
      'webhookUrl': process.env.REACT_APP_WEBHOOK_URL
    }, MERCHANT_KEY, {
      issuer: "walletpayment.net",
      expiresIn: "200m"
    });

    axios.post(REST_END_POINT + "/deposits", {}, {
      headers: {
        "Authorization": token
      },
    }).then(response => {
      document.location.href = response.request.res.responseUrl;
      console.log(response.request.res.responseUrl);
      debugger;

    }).catch(function (err) {
      debugger;
      Swal.fire({
        icon: 'error',
        title: 'Error in Processing your request',
        html: err.response.data.message || err.response.data,
        footer: '<a href="">Why do I have this issue?</a>'
      })
    });
  }

  const handleWithdraw = () => {
    var token = jwt.sign({
      'txnid': transactionID,
      'amount': amount,
      'type': type,
      'address': address,
      'cid': customerId,
      'name': customerName,
      'email': customerEmail,
      'mobile': customerPhone,
      'tokenAddress': tokenAddress,
      'webhookUrl': process.env.REACT_APP_WEBHOOK_URL
    }, MERCHANT_KEY, {
      issuer: "walletpayment.net",
      expiresIn: "200m"
    });

    axios.post(REST_END_POINT + "/requests", {}, {
      headers: {
        "Authorization": token
      },
    }).then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Request sent Successfully',
        html: "Request will be processed soon",
        footer: '<a href="">Why do I have this issue?</a>'
      })

    }).catch(function (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in Processing your request',
        html: err.response.data,
        footer: '<a href="">Why do I have this issue?</a>'
      })
    });
  }


  return (
    <Box sx={{ width: '100%' }}>
      <ButtonAppBar />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Deposit" {...a11yProps(0)} />
          <Tab label="Whitelist" {...a11yProps(1)} />
          <Tab label="Withdraw" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          hanldeDeposit();
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer ID"
                defaultValue={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Name"
                defaultValue={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Email"
                type="email"
                defaultValue={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Phone"
                defaultValue={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Transaction Coins"
                defaultValue={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Wallet Type</InputLabel>
                <Select
                  value={type}
                  label="Wallet Type"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"BNB"}>BNB</MenuItem>
                  <MenuItem value={"BTC"}>BTC</MenuItem>
                  <MenuItem value={"TRX"}>TRX</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Checkbox checked={isToken} onChange={(e) => setToken(e.target.checked)} />
              Is Token Transfer
              {
                isToken && <TextField

                  label="Contract Address"
                  defaultValue={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                />
              }

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button type="submit" fullWidth variant="contained">Pay Now</Button>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleWhitelist();
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer ID"
                defaultValue={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Name"
                defaultValue={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Email"
                type="email"
                defaultValue={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Phone"
                defaultValue={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Wallet Type</InputLabel>
                <Select
                  value={type}
                  label="Wallet Type"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"BNB"}>BNB</MenuItem>
                  <MenuItem value={"BTC"}>BTC</MenuItem>
                  <MenuItem value={"TRX"}>TRX</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Wallet Address"
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Min Coins"
                defaultValue={minCoins}
                onChange={(e) => setMinCoins(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Max Coins"
                defaultValue={maxCoins}
                onChange={(e) => setMaxCoins(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Cooling Period (minutes)"
                defaultValue={coolingPeriod}
                onChange={(e) => setCoolingPeriod(e.target.value)}
                fullWidth
                helperText="for instant withdraw, please use -1"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button type="submit" fullWidth variant="contained">Whitelist Wallet</Button>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleWithdraw();
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer ID"
                defaultValue={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Name"
                defaultValue={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Email"
                type="email"
                defaultValue={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Customer Phone"
                defaultValue={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Wallet Address"
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField

                label="Transaction Coins"
                defaultValue={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Wallet Type</InputLabel>
                <Select
                  value={type}
                  label="Wallet Type"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"BNB"}>BNB</MenuItem>
                  <MenuItem value={"BTC"}>BTC</MenuItem>
                  <MenuItem value={"TRX"}>TRX</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Checkbox checked={isToken} onChange={(e) => setToken(e.target.checked)} />
              Is Token Transfer
              {
                isToken && <TextField

                  label="Contract Address"
                  defaultValue={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                />
              }

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button type="submit" fullWidth variant="contained">Withdraw Now</Button>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
    </Box>
  );
}